import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import SubmitButton from '../Button/SubmitButton';

interface ConfirmModalProps {
  onSubmit: VoidFunction;
  isClose: VoidFunction;
  message: string;
  ButtonText?: string;
  
}

const ConfirmModal = ({
  onSubmit,
  isClose,
  message,
  ButtonText,
}: ConfirmModalProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box sx={{ maxWidth: '818px', maxHeight: '80vh' }}>
        <Box
          sx={{
            width: { xs: '305px', sm: '540px', md: '600px' },
            minWidth: '305px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.colors?.buttonBackground.default,
              height: '63px',
              borderRadius: '10px',
            }}
          >
            <Typography
              color={theme.colors?.white.default}
              fontSize='20px'
              fontWeight={700}
            >
              Confirm
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 5,
            }}
          >
            <Typography color={'primary'} fontSize={isMobile ?'18px' : '20px'}>
              {message}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 4,
            }}
          >
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
              width='100%'
            >
              <Button
                sx={{
                  backgroundColor: '#BFA980',
                  width: '260px',
                  height: '60px',
                  borderRadius: '17px',
                }}
                variant='contained'
                size='large'
                onClick={() => {
                  isClose();
                }}
              >
                cancel
              </Button>
              {/* <Button
                sx={{
                  backgroundColor: '#002E51',
                  width: '260px',
                  height: '60px',
                  borderRadius: '17px',
                }}
                variant='contained'
                size='large'
                onClick={onSubmit}
              >
                {ButtonText ? ButtonText : 'Delete'}
              </Button> */}
              <SubmitButton onClick={onSubmit} loader={false} buttonName={ButtonText ?? 'Delete'}/>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmModal;
