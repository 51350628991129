import { IAPIResponse, Obj } from './../types/interfaces/index';
import { AxiosInstance, AxiosResponse } from 'axios';

interface ICompanyService {
  getComplianceStatus: (
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
    search?: string,
    filterQuery?: string,
    deleted?: string,
    assignedToMe?: string,
    companyId?: string,
    companyType?: string
  ) => Promise<IAPIResponse<Obj>>;
  getCompanyProfileDetails: (id: string) => Promise<IAPIResponse<Obj>>;
  getPlans: () => Promise<
    IAPIResponse<{ _id: string; name: string; price: number }[]>
  >;
  setCompanyProfile: (body: any | unknown) => Promise<IAPIResponse>;
  getCompanyType: () => Promise<IAPIResponse>;
  getCompanyData: (key: string) => Promise<IAPIResponse>;
  deleteCompanyDocument: (key: string) => Promise<IAPIResponse>;
  deleteRecruitmentHistory: (id: string) => Promise<IAPIResponse>;
  getDocumentData: () => Promise<IAPIResponse>;
  setDocumentData: (formData: FormData) => Promise<IAPIResponse>;
  addDocumentData: (formData: FormData) => Promise<IAPIResponse>;
  getDataFromCompanyHouse: (body: any | unknown) => Promise<IAPIResponse>;
  getCompanyStatus: (companyId: string | undefined) => Promise<IAPIResponse>;
  setAccurateData: (
    key: string,
    companyId: string | undefined,
    index?: number
  ) => Promise<IAPIResponse>;
  addDataFromCompanyHouse: (body: any) => Promise<IAPIResponse<any>>;
  companyProfileHistory: (
    id: string,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
    search?: string,
    filter?: number,
    completionTracking?: string
  ) => Promise<IAPIResponse<any>>;
  getProfileHistory: (id: string) => Promise<IAPIResponse>;
  getIsMonthlyCheck: () => Promise<IAPIResponse>;
  employeeProfileHistory: (
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
    search?: string,
    filter?: number,
    completionTracking?: string
  ) => Promise<IAPIResponse<any>>;
  setSmsAccurateData: (
    key: string,
    companyId: string | undefined,
    index?: number
  ) => Promise<IAPIResponse>;
  dashboardCompliance: (
    id: string,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
    search?: string
  ) => Promise<IAPIResponse>;
  companyDashboardStatus: () => Promise<IAPIResponse>;
  getSmsMileStone: () => Promise<
    IAPIResponse<
      { percentage: number; service: string; value: string; _id: string }[]
    >
  >;
  getSubscription: () => Promise<
    IAPIResponse<
      { _id: string; name: string; price: number; isCurrent: boolean ,totalEmployee:number; totalEmployeeLimit: number}[]
    >
  >;
  updateSubscription: (data: {
    planId: string;
    currentPlan: string;
    message: string;
  }) => Promise<IAPIResponse>;
  getCompanyName: () => Promise<IAPIResponse>;
  createRecruitmentHistory: (body: Obj) => Promise<IAPIResponse>;
  recruitmentHistory: (
    id: string,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string,
    search?: string,
    filter?: number,
    completionTracking?: string
  ) => Promise<IAPIResponse<any>>;
  getRecruitmentHistoryList: () => Promise<IAPIResponse>;
  setRecruitmentIdForEmployee: (id: string, body: Obj) => Promise<IAPIResponse>;
  getRecruitmentIdForEmployee: (id: string) => Promise<IAPIResponse>;
  updateRecruitmentHistory: (
    id: string,
    body: FormData
  ) => Promise<IAPIResponse>;
  getRecruitmentHistoryEmployee: (id: string) => Promise<IAPIResponse>;
  RecruitmentHistoryStatus: (id: string, data: string) => Promise<IAPIResponse>;
  getDocument: (url: string, type?: string) => Promise<IAPIResponse>;
  getEmployeeList: () => Promise<IAPIResponse>;
  getServiceMileStone: (type: string) => Promise<IAPIResponse>;
  updateEmployeeInRecruitment: (id: string, body: Obj) => Promise<IAPIResponse>;
  activeEmployees: (data?: {
    companies: string[];
  }) => Promise<
    IAPIResponse<{ _id: string; name: string; companyId: string }[]>
  >;
  createHoliday: (data: Obj) => Promise<IAPIResponse>;
  updateHoliday: (data: Obj) => Promise<IAPIResponse>;
  readHoliday: (region: string, filter?: string) => Promise<IAPIResponse<any>>;
  deleteHoliday: (id: string) => Promise<IAPIResponse>;

  getNumberOfLeaveDetails: (id: string | undefined) => Promise<
    IAPIResponse<{
      [x: string]: any;
      _id: string;
      weeklyWorkingPattern: number;
      totalAnnualLeaves: number;
      annualLeaveStartDate: string;
      annualLeaveEndDate: string;
    }>
  >;
  updateNumberOfLeave: (
    companyId: string | undefined,
    data: object
  ) => Promise<IAPIResponse>;
  readRegions: () => Promise<IAPIResponse<any>>;
  getCompanyRegion: (
    companyId: string
  ) => Promise<IAPIResponse<{ regionForPublicHolidays: string }>>;
  updateHolidayRegion: (companyId: string, body: any) => Promise<IAPIResponse>;
  getSubCompanyList: (companyId: string) => Promise<IAPIResponse>;
  getAccessCompanyList: (companyId: string) => Promise<IAPIResponse>;
  changeCompany: (companyId: string) => Promise<IAPIResponse>;

  reSendEmail: (
    type: string,
    companyId: string,
    userId: string
  ) => Promise<IAPIResponse<any>>;
  getMultiCompanyDashboardTableData: (
    userId: string,
    rows?: number,
    page?: number,
    sortBy?: string,
    orderBy?: string
  ) => Promise<IAPIResponse<{data:[],count:number}>>;
  updateCompanyComplianceStatus: (companyId: string) => Promise<IAPIResponse>;
   getDocumentTypes: (
    type: string,
    isRestricted?: boolean
  ) => Promise<IAPIResponse<{ name: string }[]>>;
}

const companyService = (httpClient: AxiosInstance): ICompanyService => {
  return {
    getPlans: async (): Promise<
      IAPIResponse<{ _id: string; name: string; price: number }[]>
    > => {
      try {
        const res: AxiosResponse = await httpClient.get('/app/user/plans/list');
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getCompanyProfileDetails: async (id): Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/company/details/${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Something Went Wrong.' };
      }
    },
    reSendEmail: async (
      type,
      companyId,
      userId
    ): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/agency/re-send-email?type=${type}&companyId=${companyId}&userId=${userId}`
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Error.',
        };
      }
    },
    getComplianceStatus: async (
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filterQuery,
      deleted,
      assignedToMe,
      companyId,
      companyType
    ): Promise<IAPIResponse<Obj>> => {
      try {
        const res = await httpClient.get(
          `/app/company/business-admins?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filterQuery}&deleted=${deleted}&assignedToMe=${assignedToMe}&masterCompanyId=${
            companyId ?? ''
          }&companyType=${companyType ?? ''}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get compliance status.' };
      }
    },
    setCompanyProfile: async (body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/company-profile',
          body
        );
        return res?.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    setDocumentData: async (formData: FormData): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/company/document-upload',
          formData
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },

    addDocumentData: async (formData: FormData): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/company/other-document',
          formData
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid', data: {} };
      }
    },
    getCompanyType: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/company/types'
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getCompanyData: async (key): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company-user/details/?key=${key}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getDocumentData: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/company/documents'
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getDataFromCompanyHouse: async (body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/business-house',
          body
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },

    addDataFromCompanyHouse: async (body): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/business-house-sync',
          body
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getCompanyStatus: async (companyId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/profile/details/status?companyId=${companyId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    setAccurateData: async (key, companyId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/profile/status?key=${key}&companyId=${companyId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    companyProfileHistory: async (
      id,
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter,
      completionTracking
    ): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/profile/history/${id}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}&completionTracking=${completionTracking}`
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to get company profile history.',
        };
      }
    },
    getProfileHistory: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/profile/history${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getIsMonthlyCheck: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/business/monthly-check'
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    employeeProfileHistory: async (
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter,
      completionTracking
    ): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/employee/profile/history?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}&completionTracking=${completionTracking}`
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to get employee profile history.',
        };
      }
    },
    setSmsAccurateData: async (
      key,
      companyId,
      index
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/profile/sms-user/status?key=${key}&companyId=${companyId}&index=${index}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    dashboardCompliance: async (
      id,
      rows,
      page,
      sortBy,
      orderBy,
      search
    ): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/dashboard/compliance/${id}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to get compliance history.',
        };
      }
    },
    companyDashboardStatus: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/company/dashboard'
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed.',
        };
      }
    },
    getSmsMileStone: async (): Promise<
      IAPIResponse<
        { percentage: number; service: string; value: string; _id: string }[]
      >
    > => {
      try {
        const res: AxiosResponse = await httpClient.get('/app/mile-stone');
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getSubscription: async (): Promise<
      IAPIResponse<
      { _id: string; name: string; price: number; isCurrent: boolean ;totalEmployee:number; totalEmployeeLimit: number}[]
      >
    > => {
      try {
        const res: AxiosResponse = await httpClient.get('/app/user/plans/list');
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    updateSubscription: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          '/app/user/subscription',
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getCompanyName: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/company/name'
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get company name.' };
      }
    },
    createRecruitmentHistory: async (body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/recruitment-history',
          body
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    recruitmentHistory: async (
      id,
      rows,
      page,
      sortBy,
      orderBy,
      search,
      filter,
      completionTracking
    ): Promise<IAPIResponse<any>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/recruitment-history/${id}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}&filter=${filter}&completionTracking=${completionTracking}`
        );
        return res.data;
      } catch (error) {
        return {
          status: false,
          message: 'Failed to get company profile history.',
        };
      }
    },
    getRecruitmentHistoryList: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/recruitment/list'
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    setRecruitmentIdForEmployee: async (id, body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          `/app/user/recruitment/set/employeeId/${id}`,
          body
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    getRecruitmentIdForEmployee: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/recruitment/get/employee/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    updateRecruitmentHistory: async (id, body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/recruitment/update/${id}`,
          body
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    getRecruitmentHistoryEmployee: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/get/employee/recruitment/history/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },

    RecruitmentHistoryStatus: async (id, data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/recruitment-history/status/${id}/${data}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    getDocument: async (url, type): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `app/user/fetch/document/?url=${url}&type=${type}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    deleteCompanyDocument: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `app/user/company/document/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    getEmployeeList: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          'app/user/employee-list/recruitment'
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    updateEmployeeInRecruitment: async (id, body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/employee-update/recruitment/${id}`,
          body
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    deleteRecruitmentHistory: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `app/user/recruitment/history/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    activeEmployees: async (
      data
    ): Promise<
      IAPIResponse<{ _id: string; name: string; companyId: string }[]>
    > => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/user/report/employees',
          data
        );
        return res?.data;
      } catch (err) {
        return { status: false, message: 'Failed to get active companies.' };
      }
    },
    getServiceMileStone: async (
      type
    ): Promise<
      IAPIResponse<
        { percentage: number; service: string; value: string; _id: string }[]
      >
    > => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/service/mile-stone?type=${type}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    createHoliday: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.post(
          '/app/company/create/company-holiday',
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to create service request type.',
        };
      }
    },
    updateHoliday: async (data): Promise<IAPIResponse<Obj>> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/company/update/company-holiday/${data._id}`,
          data
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to update service request type.',
        };
      }
    },
    readHoliday: async (region, filter): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/company/company-holidays?filter=${filter}&region=${region}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get company name.' };
      }
    },
    deleteHoliday: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.delete(
          `/app/company/remove/company-holiday/${id}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to delete service request type.',
        };
      }
    },
    getNumberOfLeaveDetails: async (
      id
    ): Promise<
      IAPIResponse<{
        _id: string;
        weeklyWorkingPattern: number;
        totalAnnualLeaves: number;
        annualLeaveStartDate: string;
        annualLeaveEndDate: string;
      }>
    > => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/weekly-working-pattern/${id}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    updateNumberOfLeave: async (id, data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.patch(
          `/app/user/company/weekly-working-pattern/${id}`,
          data
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Error.' };
      }
    },
    readRegions: async (): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          '/app/user/company/bank/holidays/regions'
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get company name.' };
      }
    },
    getCompanyRegion: async (companyId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/bank/holidays/region/${companyId}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get company name.' };
      }
    },
    updateHolidayRegion: async (companyId, body): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/company/bank/holidays/region/update/${companyId}`,
          body
        );
        return res?.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
    getSubCompanyList: async (companyId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/company/get/child-company/${companyId}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get company list.' };
      }
    },
    changeCompany: async (companyId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/user/change/company/${companyId}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to change company.' };
      }
    },
    getAccessCompanyList: async (userId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/access/companies/${userId}`
        );
        return res.data;
      } catch (err) {
        return { status: false, message: 'Failed to get company list.' };
      }
    },
    getMultiCompanyDashboardTableData: async (
      userId,
      rows,
      page,
      sortBy,
      orderBy,
    ): Promise<IAPIResponse<{data:[],count:number}>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/access/company/dashboard/${userId}?rows=${rows}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Failed fetch data.' };
      }
    },
    updateCompanyComplianceStatus: async (companyId): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse = await httpClient.put(
          `/app/update/company/complaint/status/${companyId}`
        );
        return res.data;
      } catch (err) {
        return {
          status: false,
          message: 'Failed to update compliance status.',
        };
      }
    },
    getDocumentTypes: async (
      type,
      isRestricted
    ): Promise<IAPIResponse<{ name: string }[]>> => {
      try {
        const res: AxiosResponse = await httpClient.get(
          `/app/user/document/types?type=${type}&isRestricted=${
            isRestricted ? isRestricted : false
          }`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: 'Error' };
      }
    },
  };
};
export default companyService;
